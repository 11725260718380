import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import axios, { API } from 'lib/config/axios';
import { TextField, Grid, Autocomplete } from '@mui/material';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/features/ui-slice';

const PREFIX = 'CountryRegionDropdowns';

const classes = {
  dropdownContainer: `${PREFIX}-dropdownContainer`,
  itemError: `${PREFIX}-itemError`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.dropdownContainer}`]: {
    '&.MuiGrid-root': {
      marginBottom: theme.spacing(1.5),
    },
  },

  [`& .${classes.itemError}`]: {
    display: 'block',
  },
}));

const CountryRegionDropdowns = (props) => {
  const { i18nDropdowns, data, handleSelectChange } = props;

  const [regionOptions, setRegionOptions] = useState(
    data.region?.id ? [data.region] : [{ name: null, id: null }],
  );
  const [regionDisable, setRegionDisable] = useState(!data.region?.id);

  const resetData = () => {
    handleSelectChange({ name: null, id: null }, 'region');
    handleSelectChange({ name: null, id: null }, 'geography');
    setRegionOptions([{ name: null, id: null }]);
  };

  const handleCountryChange = (country) => {
    if (country == null) {
      resetData();
      setRegionDisable(true);
    } else if (
      !(
        country
        && data.countries.filter(
          (item) => item.id == country.id && item.has_regions,
        ).length
      )
    ) {
      // checking if have value and has no region
      resetData();
      setRegionDisable(true);
    } else {
      // checking if have value and has region
      setRegionDisable(false);
    }
    handleSelectChange(country, 'country');
  };

  // Geting Region Option
  const handleErrorResponse = useErrorsHandler();
  const dispatch = useDispatch();
  let cancelToken;

  const getRegionData = (countryId) => {
    dispatch(setLoading(true));

    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    setRegionOptions([{ name: null, id: null }]);
    axios
      .get(
        API.regions,
        {
          params: {
            country_id: countryId,
          },
        },
        { cancelToken: cancelToken.token },
      )
      .then((res) => {
        setRegionOptions(res.data.regions);
      })
      .catch((error) => {
        handleErrorResponse(error, true);
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <StyledGrid container spacing={3} className={classes.dropdownContainer}>
      <Grid item md={6} xs={12}>
        <Autocomplete
          id="er-country"
          options={data.countries}
          openOnFocus
          getOptionLabel={(option) => option.name || ''}
          value={data.country || null}
          disabled={data.disabledCountry && data.disabledCountry}
          isOptionEqualToValue={(option, value) => option.name == value.name}
          onChange={(e, value) => handleCountryChange(value, 'country')}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18nDropdowns.countryLabel}
              variant="outlined"
              color="secondary"
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
              error={Boolean(data.countryError)}
              helperText={data.countryError?.map((err) => (
                <span className={classes.itemError} key={err}>
                  {err}
                </span>
              ))}
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Autocomplete
          id="er-region"
          options={regionOptions}
          openOnFocus
          getOptionLabel={(option) => option.name || ''}
          value={data.region || null}
          isOptionEqualToValue={(option, value) => option.name == value.name}
          onChange={(e, value) => handleSelectChange(value, 'region')}
          disabled={data.disabledRegion ? data.disabledRegion : regionDisable}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18nDropdowns.regionLabel}
              variant="outlined"
              color="secondary"
              required
              onFocus={() => getRegionData(data.country.id)}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
              error={Boolean(data.regionError)}
              helperText={data.regionError?.map((err) => (
                <span className={classes.itemError} key={err}>
                  {err}
                </span>
              ))}
            />
          )}
        />
      </Grid>
    </StyledGrid>
  );
};

export default CountryRegionDropdowns;
