/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable mui-unused-classes/unused-classes */
import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';

const PREFIX = 'CreditCardList';

const classes = {
  root: `${PREFIX}-root`,
  labelRootOverride: `${PREFIX}-labelRootOverride`,
  labelOption: `${PREFIX}-labelOption`,
  active: `${PREFIX}-active`,
  cardInfo: `${PREFIX}-cardInfo`,
  cardIcon: `${PREFIX}-cardIcon`,
  cardNumber: `${PREFIX}-cardNumber`,
  cardOwner: `${PREFIX}-cardOwner`,
  expireAt: `${PREFIX}-expireAt`,
  selectedCard: `${PREFIX}-selectedCard`,
  checkIcon: `${PREFIX}-checkIcon`,
  addNew: `${PREFIX}-addNew`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.labelRootOverride}`]: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      // marginLeft: theme.spacing(-3),
    },
  },

  [`& .${classes.labelOption}`]: {
    padding: theme.spacing(1.5, 1),
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
    margin: theme.spacing(1.5, 0),
    border: `1px solid ${theme.palette.grey[100]}`,
    alignItems: 'center',
    [`&.${classes.active}`]: {
      borderColor: theme.palette.grey[600],
      backgroundColor: theme.palette.secondary.dark,
      [`& .${classes.cardNumber}, & .${classes.cardOwner}, & .${classes.expireAt}`]:
        {
          color: theme.palette.common.fogWhite,
        },
    },
  },

  [`& .${classes.active}`]: {},

  [`& .${classes.cardInfo}`]: {
    display: 'flex',
    '& > *': {
      // marginRight: theme.spacing(2),
    },
    [`& .${classes.cardIcon}`]: {
      width: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(3.5),
      },
      marginRight: theme.spacing(1),
    },
    [`& .${classes.cardNumber}`]: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.grey[800],
      marginRight: theme.spacing(4),
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
      },
    },
    [`& .${classes.cardOwner}, & .${classes.expireAt}`]: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.grey[300],
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(3),
      },
    },
  },

  [`& .${classes.cardIcon}`]: {},
  [`& .${classes.cardNumber}`]: {},
  [`& .${classes.cardOwner}`]: {},
  [`& .${classes.expireAt}`]: {},

  [`& .${classes.selectedCard}`]: {
    display: 'flex',
    opacity: 0.5,
    [`& .${classes.checkIcon}`]: {
      marginRight: theme.spacing(1),
    },
  },
  [`& .${classes.checkIcon}`]: {},

  [`& .${classes.addNew}`]: {
    textAlign: 'right',
  },
}));

const CreditCardList = ({ onAddNewCard, creditCardOption }) => {
  const { t } = useTranslation('fe_er_checkout_page');
  const theme = useTheme();

  const handleChange = (event) => {
    creditCardOption.setCreditCardErrors({});
    creditCardOption.setSelectedCardId(String(event.target.value));
  };
  useEffect(() => {
    creditCardOption.creditCardsList.length > 0
      && !creditCardOption.selectedCardId
      && creditCardOption.setSelectedCardId(
        String(creditCardOption.creditCardsList[0].id),
      );
  }, []);

  return (
    <Root className={classes.root}>
      <RadioGroup
        aria-label="payment_method"
        name="payment_method"
        value={creditCardOption.selectedCardId}
        onChange={handleChange}>
        {creditCardOption?.creditCardsList && (
          <>
            {creditCardOption.creditCardsList.map((credit_card) => (
              <React.Fragment key={credit_card.id}>
                <FormControlLabel
                  key={credit_card.id}
                  value={String(credit_card.id)}
                  control={<Radio color="primary" size="small" />}
                  label={(
                    <>
                      <Grid container className={classes.cardInfo}>
                        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                          <img
                            className={classes.cardIcon}
                            src={`/imgs/payments/${credit_card.type_identifier}.svg`}
                            alt={credit_card.name}
                          />
                          <Typography className={classes.cardNumber}>
                            {`${credit_card.type_name} **** ${credit_card.last_digits}`}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ whiteSpace: 'nowrap', display: 'flex' }}>
                          <Typography className={classes.cardOwner}>
                            {`${credit_card.first_name} ${credit_card.last_name}`}
                          </Typography>
                          <Typography className={classes.expireAt}>
                            {`${credit_card.month}/${credit_card.year}`}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                          {credit_card.id ==
                            creditCardOption.selectedCardId && (
                            <div className={classes.selectedCard}>
                              <CheckIcon className={classes.checkIcon} />
                              <span>{t(fe_checkout_page:selected")}</span>
                            </div>
                          )}
                        </Grid> */}
                      </Grid>
                    </>
                  )}
                  classes={{
                    root: classes.labelRootOverride,
                    label: clsx(classes.labelOption, {
                      [classes.active]:
                        credit_card.id == creditCardOption.selectedCardId,
                    }),
                  }}
                />
                {String(credit_card.id)
                  == String(creditCardOption.selectedCardId)
                  && creditCardOption.creditCardErrors
                  && creditCardOption.creditCardErrors.errors && (
                    <Grid item xs={12}>
                      {Object.keys(
                        creditCardOption.creditCardErrors.errors,
                      ).map((error, index) => (
                        <Box
                          mb={2}
                          color={theme.palette.error.main}
                          key={index}>
                          {creditCardOption.creditCardErrors.errors[error]}
                        </Box>
                      ))}
                    </Grid>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </RadioGroup>
      <div className={classes.addNew}>
        <Button
          type="submit"
          variant="text"
          color="primary"
          size="large"
          onClick={onAddNewCard}>
          {t('fe_er_checkout_page:add_a_new_card')}
        </Button>
      </div>
    </Root>
  );
};

export default CreditCardList;
